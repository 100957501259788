.is-login {
  align-items: center;

  span {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }
}

.horizontal-separator { 
  height: 1px;
  background: linear-gradient(90deg, transparent, #FFFFFF, transparent);
  flex-grow: 1;
}
