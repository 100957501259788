@import 'assets/fonts/fonts.css';

.ant-select .ant-select-selector.ant-selector-padding {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

@media only screen and (max-width: 1400px) {
  .ant-select .ant-select-selector.ant-selector-padding {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }
}

.ant-select .search-bar {
  height: 30px;
  align-self: center;
  display: flex;
  flex-direction: column;
  right: 0px !important;
  left: 0px !important;
  width: 100%;
  position: relative !important;
}

.ant-select:focus .ant-select-selection-item {
  display: none;
}

.ant-select .ant-search-background {
  background-color: white !important;
}

.ant-select .hide-element {
  display: none;
}


.security-autocomplete {
  .ant-select {
    height: 70px;
    border-radius: 75px;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: #474c55;
  }

  .ant-select-show-search {
    width: 100%;
  }

  .ant-select-selector {
    height: 70px !important;
    border-radius: 75px !important;
    box-shadow: inset -5px -5px 15px rgba(122, 124, 130, 0.4);
    background: linear-gradient(269.37deg,
        rgb(124 124 124 / 50%) 0%,
        rgba(71, 76, 85, 0.5) 100%),
      #919399;
    backdrop-filter: blur(70px);

    .ant-select-selection-placeholder {
      color: #474c55;
      transition: all 0.1s;
      text-align: center;
      padding: 0;
      margin: auto;
    }

    .ant-select-selection-search {
      color: #474c55;
    }

    .ant-select-selection-item {
      color: #474c55;
      font-weight: 300;
      transition: all 0.1s;
      text-align: center;
    }

    .ant-select-selection-search-input {
      text-align: center;
      height: 100% !important;
    }
  }
}

// .ant-select .ant-search-bar {
//   display: flex;
// }
.ant-select-dropdown {
  background: linear-gradient(269.37deg,
      rgb(124 124 124 / 50%) 0%,
      rgba(71, 76, 85, 0.5) 100%),
    #919399;

  .ant-select-item-option-active,
  .ant-select-item-option-selected {
    background: linear-gradient(269.37deg,
        rgb(124 124 124 / 50%) 0%,
        rgba(71, 76, 85, 0.5) 100%),
      #919399;
  }
}

.pane-articles.w-1\/3 {
  @import './eta-showcase.mobile.scss';
}

.eta-text-shadow {
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.55);
}

.eta-showcase {
  &.container {
    max-width: 100%;
  }

  .eta-header {
    background: linear-gradient(169.03deg,
        rgba(255, 255, 255, 0.4) -0.81%,
        rgba(255, 255, 255, 0) 136.27%);
  }

  .eta-perspective-switch {
    background: #474c55;
    height: 30px;
    width: 60px;

    .ant-switch-handle {
      width: 28px;
      height: 28px;
      top: 1px;

      &::before {
        border-radius: 100%;
        background: #595D65;
        box-shadow: 1px 1px 2px rgba(71, 74, 81, 0.2), -1px -1px 2px rgba(71, 74, 81, 0.2), 1px -1px 2px rgba(117, 122, 131, 0.9), -1px 1px 3px rgba(71, 74, 81, 0.9);
      }
    }

    &.ant-switch-checked {
      .ant-switch-handle {
        right: 2px;
        left: 30px;
      }
    }
  }

  .eta-content {
    .eta-profile-info {
      .eta-label-content {
        @mixin content-border-styles($name, $color) {
          &.border-#{$name} {
            border-color: $color;
            box-shadow: 3px 2px 18px $color;
          }
        }

        @include content-border-styles('MaxGrowth', #cbebf7);
        @include content-border-styles('PureGrowth', #cce9e3);
        @include content-border-styles('GrowthGuard', #a94447);
        @include content-border-styles('MaxDiv', #f3dbd5);
        @include content-border-styles('PureDiv', #3d8e74);
        @include content-border-styles('DivGuard', #426299);
      }
    }

    .eta-showcase-content {
      user-select: none;

      .add-btn {
        border-radius: 1px;
      }

      .showCasePayoffChart {
        background: linear-gradient(152.97deg,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%),
          rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(34.4454px);
        margin-top: 30px;
        padding-bottom: 20px;

        .chartHeader {
          color: grey;
          font-size: 13px;
          padding: 12px 12px 0px 12px;

          .growth-blue-text {
            color: #65cdf3;
          }

          .growth-green-text {
            color: #c7db6d;
          }

          .growth-red-text {
            color: #db3155;
          }

          .income-blue-text {
            color: #1c59a8;
          }

          .income-green-text {
            color: #73bd59;
          }

          .income-red-text {
            color: #f5bd1a;
          }
        }
      }
    }
  }

  .eta-action-items {
    .ant-btn-primary {
      font-family: 'DIN 2014 bol';
      background: linear-gradient(152.97deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%);
      backdrop-filter: blur(42px);
      border: 0.5px solid #fff;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      z-index: 1;

      &.active {
        background: rgba(255, 255, 255, 0.7);
        color: #151515;
      }

      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.7);
        color: #151515;
      }
    }
  }

  .details {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid #ffffff;
    box-sizing: border-box;
    cursor: pointer;
    color: #2c2f33;
    font-size: 22px;
  }

  .eta-slider-content {
    .ant-slider-disabled .ant-slider-track {
      background-color: #9fa1a3 !important;
    }
  }

  .eta-content__info {
    font-weight: 400;
    font-size: 24px;
  }

  .eta-expanded {
    width: 100%;

    .header-content {
      border: 0.5px solid #ffffff;
      box-sizing: border-box;
      border-radius: 0px;

      .allocation-section {
        border-bottom: 1px solid #fff;
      }

      .label-item {
        font-size: 11px;
        color: #000000;
      }

      .value-item {
        font-size: 11px;
        color: #fff;
      }

      .learn-more {
        border: 0.5px solid #ffffff;
        box-sizing: border-box;
        border-radius: 1px;
        padding: 1px;
      }

      .price-section {
        line-height: 14px;
      }
    }

    .eta-content-section {
      color: #000000;
      border: 0.5px solid #ffffff;
      box-sizing: border-box;
      border-radius: 0px;

      .section-range-value {
        font-size: 42px;
      }

      .section-range-decimal {
        font-size: 30px;
      }

      .capital-guard-container {
        svg {
          width: 100px;
          height: 120px;
        }
      }

      .capital-guard-content {
        position: absolute;
        top: 25px;

        .capital-guard-value {
          line-height: 30px;

          .capital-value-range {
            font-size: 36px;
          }

          .capital-value-decimal {
            font-size: 19px;
          }
        }

        .capital-guard-label {
          font-size: 10px;
        }
      }
    }

    .footer-content {
      background: linear-gradient(152.97deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%);
      backdrop-filter: blur(42px);
      border: 1px solid #fff;

      .section-separator {
        border-right: 1px solid #fff;
      }

      .slider-buttons {
        cursor: pointer;
        border-bottom: 1px solid #fff;
      }

      .up-arrow {
        border-right: 1px solid #fff;
      }

      .down-arrow,
      .up-arrow {
        padding-right: 0px;
        padding-left: 0px;

        svg {
          width: 40px;
          height: auto;
        }
      }

      .share-target-info,
      .last-offer-info {
        color: #000000;
      }

      .add-portofolio {
        border-top: 1px solid #fff;
        color: #000000;
      }
    }
  }
}

@media (max-width: 1400px) {
  #eta-cube {
    .eta-profile-content {
      &__range {
        &-value {
          font-size: 35px;
        }

        &-decimal {
          font-size: 25px;
        }
      }

      &__range-label {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 500px) {
  @import './eta-showcase.mobile.scss';
}