.all-etas-list>div {
  padding-left: 12px;
  padding-right: 12px;
}

@mixin markets-table-eta-content($name, $background) {
  .background-#{$name} {
    background: $background;
  }
}



@include markets-table-eta-content('MaxGrowth',
  linear-gradient(180deg, #cbebf7 0.58%, #8ea9ba 96.61%));
@include markets-table-eta-content('PureGrowth',
  linear-gradient(180deg, #cce9e3 -0.45%, #5c8370 96.54%));
@include markets-table-eta-content('GrowthGuard',
  linear-gradient(180deg, #a94447 0.61%, #551919 100.58%));
@include markets-table-eta-content('UltraGrowth',
  linear-gradient(180deg, #dfe0f1 -1.45%, #a198b2 101.64%));
@include markets-table-eta-content('MaxDiv',
  linear-gradient(180deg, #FCF4ED -0.45%, #C1AA9F 93.76%));
@include markets-table-eta-content('PureDiv',
  linear-gradient(180deg, #3D8E74 -0.42%, #205544 96.57%));
@include markets-table-eta-content('DivGuard',
  linear-gradient(0deg, #1A2741 1.39%, #426299 48.61%));
@include markets-table-eta-content('UltraGuard',
  linear-gradient(180deg, #7268af 1.61%, #7268af 2.59%, #342c53 99.88%));

.markets-eta-tablist {
  background: linear-gradient(0deg, #474c55, #474c55),
    linear-gradient(0deg, #797d82, #797d82),
    linear-gradient(169.03deg,
      rgba(255, 255, 255, 0.4) -0.81%,
      rgba(255, 255, 255, 0) 136.27%);
  box-shadow: 0px -1px 30px rgba(71, 76, 85, 0.3);
}

.eta-btn {
  color: #7e8288;
  box-shadow: -1px 1px 2px rgba(61, 65, 74, 0.2),
    1px -1px 2px rgba(61, 65, 74, 0.2), -1px -1px 2px rgba(63, 67, 76, 0.9),
    1px 1px 3px rgba(61, 65, 74, 0.9);
  backdrop-filter: blur(25.7666px);
  border-radius: 1px;
  border: 0.7px solid #8b8e94;

  &.active {
    color: white;
  }

  @mixin eta-btn-bg($name, $background, $boxShadow, $border) {
    &.active-#{$name} {
      border: .5px solid $border;
      background: $background;
      box-shadow: $boxShadow;
      backdrop-filter: blur(25.7666px);
    }
  }

  @include eta-btn-bg('MaxGrowth',
    rgba(177, 214, 232, 0.4),
    (-1px 1px 2px rgba(61, 65, 74, 0.2), 1px -1px 2px rgba(61, 65, 74, 0.2), -1px -1px 2px rgba(63, 67, 76, 0.9), 1px 1px 3px rgba(61, 65, 74, 0.9)),
    #B1D6E8);
  @include eta-btn-bg('PureGrowth',
    rgba(205, 234, 228, 0.4),
    (-1px 1px 2px rgba(61, 65, 74, 0.2), 1px -1px 2px rgba(61, 65, 74, 0.2), -1px -1px 2px rgba(63, 67, 76, 0.9), 1px 1px 3px rgba(61, 65, 74, 0.9)),
    #CDEAE4);
  @include eta-btn-bg('GrowthGuard',
    (linear-gradient(0deg, rgba(169, 68, 71, 0.2), rgba(169, 68, 71, 0.2)),
      linear-gradient(135deg, #343741 0%, #474c55 100%)),
    (-1px 1px 2px rgba(61, 65, 74, 0.2),
      1px -1px 2px rgba(61, 65, 74, 0.2),
      -1px -1px 2px rgba(63, 67, 76, 0.9),
      1px 1px 3px rgba(61, 65, 74, 0.9)),
    #A94447);
  @include eta-btn-bg('UltraGrowth',
    rgba(189, 190, 216, 0.4),
    (-1px 1px 2px rgba(61, 65, 74, 0.2), 1px -1px 2px rgba(61, 65, 74, 0.2), -1px -1px 2px rgba(63, 67, 76, 0.9), 1px 1px 3px rgba(61, 65, 74, 0.9)),
    #BDBED8);
  @include eta-btn-bg('MaxDiv',
    rgba(193, 171, 160, 0.4),
    (-1px 1px 2px rgba(61, 65, 74, 0.2), 1px -1px 2px rgba(61, 65, 74, 0.2), -1px -1px 2px rgba(63, 67, 76, 0.9), 1px 1px 3px rgba(61, 65, 74, 0.9)),
    #FCF4ED);
  @include eta-btn-bg('PureDiv',
    rgba(61, 142, 116, 0.4),
    (-1px 1px 2px rgba(61, 65, 74, 0.2),
      1px -1px 2px rgba(61, 65, 74, 0.2),
      -1px -1px 2px rgba(63, 67, 76, 0.9),
      1px 1px 3px rgba(61, 65, 74, 0.9)),
    #3D8E74);
  @include eta-btn-bg('DivGuard',
    rgba(66, 98, 153, 0.4),
    (-1px 1px 2px rgba(61, 65, 74, 0.2), 1px -1px 2px rgba(61, 65, 74, 0.2), -1px -1px 2px rgba(63, 67, 76, 0.9), 1px 1px 3px rgba(61, 65, 74, 0.9)),
    #426299);
  @include eta-btn-bg('UltraGuard',
    rgba(114, 104, 175, 0.4),
    (-1px 1px 2px rgba(61, 65, 74, 0.2), 1px -1px 2px rgba(61, 65, 74, 0.2), -1px -1px 2px rgba(63, 67, 76, 0.9), 1px 1px 3px rgba(61, 65, 74, 0.9)),
    #7268AF);
}

.eta-expand {
  @import './markets-eta.mobile.scss';
}