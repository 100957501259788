.current-portfolio {
  .section-label {
    font-size: 21px;
    color: #FFF;
  }

  .section-description {
    font-size: 12px;
    color: #FFF;
  }

  .step-info {
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid #2C2F33;
    backdrop-filter: blur(42px);
    border-radius: 3px;

    .step-label {
      background: linear-gradient(0deg, #2C2F33, #2C2F33), linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      border: 1px solid #2C2F33;
      backdrop-filter: blur(42px);
      color: #FFF;
      font-weight: bold;
      border-radius: 1px;
      padding: 2px 10px;
      flex-shrink: 0;
    }

    .step-description {
      min-height: 29px;
      ;
      color: #FFF;
    }
  }

  .section-body {
    .security-info {
      color: #FFF;
      font-weight: bold;
      font-size: 12px;
      width: 60%;
      margin: auto;
    }

    .secuirty-autocomplete {
      width: 60%;
      margin: auto;

      .ant-select-single.ant-select-open .ant-select-selection-item {
        color: #a3a0a0;
      }

      .ant-select-show-search {
        width: 100%;
      }

      .ant-select-selector {
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
        backdrop-filter: blur(70px);

        .ant-select-selection-placeholder {
          color: #a3a0a0;
          transition: all 0.1s;
          text-align: center;
        }

        .ant-select-selection-search {
          color: #FFF;
        }

        .ant-select-selection-item {
          color: #fff;
          font-weight: bold;
          transition: all 0.1s;
          text-align: center;
        }

        .ant-select-selection-search-input {
          text-align: center;
        }
      }
    }

    .share-info {
      background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(42px);
      border-radius: 3px;

      .share-info-header {
        .label {
          font-size: 12px;
          color: #2C2F33;
          font-weight: bold;
        }
      }

      .share-input {
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
        backdrop-filter: blur(42px);
        border-radius: 3px;

        &::placeholder {
          color: #5f5e5d;
        }
      }

      .eta-content {
        background: rgba(44, 47, 51, 0.1);
        border-radius: 3px;
        padding: 5px;
      }

      .statistic-content {
        .ant-statistic-content {
          font-size: 14px;
          color: #2C2F33;
        }
      }

      .growth-text,
      .income-text {
        color: #000;
      }
    }

    .derived-content {
      .values {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 3px
      }

      .label {
        font-weight: 500;
        color: #2C2F33;
        font-size: 12px;
      }
    }

    .section-logo img {
      width: 100px;
      height: 100px;
    }

    .security-logo img {
      height: 35px;
    }
  }

  .security-tabs {
    .content {
      color: #2C2F33;
    }

    .read-more {
      color: #2C2F33;
      padding-left: 0px;
    }

    .ant-tabs-tab-btn {
      color: #2C2F33;
    }

    .ant-tabs-ink-bar {
      color: #2C2F33;
    }
  }

  .footer-info .ant-btn-primary {
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #2C2F33;
    backdrop-filter: blur(26.0522px);
    border-radius: 3.44028px;
    border-color: #2C2F33;

    .next-icon {
      display: inline;
      padding-left: 10px;
    }

  }
}

.autcomplete-dropdown {
  font-weight: 600;
  font-size: 10px;
  background-color: #F5F5F5;
  color: #5A6267;

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: #FFF;
    background-color: #2C2F33;
  }
}

.intro-label {
  font-weight: 300;
  font-size: 36px;
  color: #FFFFFF;
  margin-bottom: 17px;
}