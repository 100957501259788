@media (max-width: 768px) {
  .public-top-bar {
    background: linear-gradient(169.03deg, rgba(255, 255, 255, 0.4) -0.81%, rgba(255, 255, 255, 0) 136.27%);
    min-height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px -1px 30px rgb(71 76 85 / 30%);
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
}