.equity-card-view {
  &__code {
    margin-bottom: 3px;
    padding: 2px 3px;
    background: linear-gradient(135deg, #E5E5E5 0%, #FDFDFD 100%);
    box-shadow: -1px 1px 2px rgba(217, 217, 217, 0.2), 1px -1px 2px rgba(217, 217, 217, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(217, 217, 217, 0.9);
    border-radius: 3px;

    dd {
      margin: 0;
    }
  }
}