.not-found-container {
    background: linear-gradient(0deg, rgba(71, 76, 85, 0.3), rgba(71, 76, 85, 0.3)), conic-gradient(from 0deg at 50% 65.61%, #8B8E94 0deg, #5F6369 360deg);

    .not-found-overlay {
        background: linear-gradient(248.38deg, #A2A4A9 35.08%, #808288 96.48%);
    }

    .hex-of-light {
        background-image: url('../../assets/svg/hex-of-light.svg');

    }
    .outer-hex {
        background-image: url('../../assets/svg/404/outer-hex.svg');
        height: -webkit-fill-available;        
    }

    .inner-hex {
        background-image: url('../../assets/svg/404/innerhex.svg');
        background-size: calc(100vh/1.5);
    }

}