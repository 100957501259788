.prism-login-form {
	> span {
		background-color: #000000;
		background-image: linear-gradient(242.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
		box-shadow: 0px 1px 0px 0px rgb(137 136 136 / 70%);
		border: 0;
		&.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
			background-color: #000000;
		}
		> span {
			color: #D9D9D9;
		}
		> input {
			background-color: transparent;
			color: #D9D9D9;
			padding-left: 10px !important;
		}
	}
}

.login-box-shadow {
	box-shadow: inset 0px 1px 1px 0px rgba(137, 136, 136, 0.7);
}
