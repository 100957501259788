.map-outline {
  background: #8B8E94;
  box-shadow: inset -8px 8px 16px rgba(97, 99, 104, 0.2), inset 8px -8px 16px rgba(97, 99, 104, 0.2), inset -8px -8px 16px rgba(181, 185, 192, 0.9), inset 8px 8px 20px rgba(97, 99, 104, 0.9);
  border-radius: 51%;
  position: absolute;
  aspect-ratio: 1/1;
  width: 100%;
  // height: 100%;
}

.map-outline-inactive {
  border: 1px dashed #6C7077;
  border-radius: 51%;
  position: absolute;
  aspect-ratio: 1/1;
  width: 100%;
  // height: 100%;
  box-shadow: 1px 1px 2px 0px rgba(83, 89, 99, 0.3);
}

@supports (-webkit-touch-callout: none) {
  .map-image-wrapper {
    &.items-end {
      .ant-image {
        bottom: 20px;
      }
    }

    &.items-start {
      .ant-image {
        top: 20px;
      }
    }
  }
}