@mixin cubes-style($name, $maxHeight: 62px) {
  .cubes[data-cube*="#{$name}"] {
    width: 100%;
    height: 100%;
    max-height: $maxHeight;
    background-image: url('../assets/images/cubes/#{$name}.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@include cubes-style('MaxGrowth');
@include cubes-style('DivGuard');
@include cubes-style('PureDiv');
@include cubes-style('PureGrowth');
@include cubes-style('GrowthGuard');
@include cubes-style('MaxDiv');
@include cubes-style('RedPairing', 102px);
@include cubes-style('BluePairing', 102px);
@include cubes-style('GreenPairing', 102px);

.eta-showcase-content {
  display: flex;

  >div {
    order: 1;

    &:first-child {
      order: 2;
    }

    &:last-child {
      order: 3;
    }
  }

  .growth-rate-container {
    width: 25%;
    flex-shrink: 0;

    &.hover {
      opacity: 1;

      .growth-rate,
      .growth-profile {
        color: #ffffff;
      }
    }

    &:last-child {
      .growth-rate {
        right: auto;
        left: 25px;
      }
    }

    .growth-rate {
      position: absolute;
      right: 25px;
      left: auto;

      &:before {
        content: '';
        position: absolute;
        bottom: -15px;
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) -3.8%, #FFFFFF 51.22%, rgba(255, 255, 255, 0) 106.62%);
      }
    }
  }

  .color-buttons {
    button {
      img {
        top: 50% !important;
        left: 50% !important;
        margin-top: 2px;
        margin-left: 1.5px;
        transform: translate(-50%, -50%);
      }
    }
  }
}