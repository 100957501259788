.prism-radio-button {
	border-color: #84888e !important;
	background: linear-gradient(135deg, rgba(52, 55, 65, 0.2) 0%, rgba(71, 76, 85, 0.2) 100%);
	color: #C0C1C6;
	font-weight: 300;
	&.ant-radio-button-wrapper-checked {
		background: linear-gradient(135deg, #474C55 0%, #343741 100%);
		color: #FFFFFF;
		font-weight: 400;
		&:not(.ant-radio-button-wrapper-disabled) {
			border-color: #84888e;
		 	&:first-child {
		 		border-color: #84888e;
		 	}
		 	&::before {
		 		background-color: #84888e;
		 	}
		}
	}
	&:not(.ant-radio-button-wrapper-disabled) {
		&:hover {
			color: #FFF;
		}
	}
	&:first-child {
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
	}
	&:last-child {
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
	}
}

.min-w-fit {
	min-width: fit-content;
}
