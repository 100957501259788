.eta-button {
  &.active {
    &:before {
      content: '';
      position: absolute;
      top: 20px;
      width: 100%;
      height: 42px;
      background: linear-gradient(180deg, rgba(217, 217, 217, 0) 25%, rgba(217, 217, 217, 0.290948) 82.29%, rgba(217, 217, 217, 0.479277) 91.15%, #D9D9D9 100%);
    }
  }

  img {
    width: 60%;
  }
}