.absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.slider {
  position: relative;
  width: 100%;
  height: 5px;
  user-select: none;

  .input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    appearance: none;
    user-select: none;
    z-index: 3;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-thumb {
      width: 23px;
      height: 23px;
      border: none;
      box-shadow: none;
      background: none;
      appearance: none;
    }

    &::-moz-range-thumb {
      width: 23px;
      height: 23px;
      border: none;
      box-shadow: none;
      background: none;
      appearance: none;
    }
  }
}

.input {
  composes: absolute
}

.track {
  composes: absolute;
  border-radius: 15px;
  background-color: #212121;
  z-index: 2;

  .trackThumb {
    position: absolute;
    flex-shrink: 0;
    top: 50%;
    right: -11.5px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: #212121;
    transform: translateY(-50%);
  }
}

.range {
  composes: absolute;
  width: 100%;
  background: #F5F5F5;
  opacity: 0.7;
  box-shadow: inset -3px 3px 6px rgba(172, 172, 172, 0.2), inset 3px -3px 6px rgba(172, 172, 172, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.9), inset 3px 3px 8px rgba(172, 172, 172, 0.9);
  border-radius: 15px;
  z-index: 1;
}