.learn-more-market-eta-timeline {
  .learn-more-market-eta-timeline-header {
    background: radial-gradient(50.5% 50% at 49.48% 50%, #343741 0%, #343741 100%);

    &.red {
      &.growth {
        color: #A94447;
      }

      &.income {
        color: #FCF4ED;
      }
    }

    &.green {
      &.growth {
        color: #CDEAE4;
      }

      &.income {
        color: #3D8E74;
      }
    }

    &.blue {
      &.growth {
        color: #CBEBF7;
      }

      &.income {
        color: #426299;
      }
    }

    &.purple {
      &.growth {
        color: #DFE0F1;
      }

      &.income {
        color: #7268AF;
      }
    }
  }

  .learn-more-market-eta-timeline-calcs {
    min-height: 55px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(50.5% 50% at 49.48% 50%, #343741 0%, #343741 100%);
  }

  .learn-more-market-eta-timeline-footer-table {

    .learn-more-market-eta-timeline-footer-table-header {
      align-items: center;
      box-shadow: inset -1px 1px 2px rgba(62, 64, 68, 0.2), inset 1px -1px 2px rgba(62, 64, 68, 0.2), inset -1px -1px 2px rgba(128, 134, 142, 0.9), inset 1px 1px 3px rgba(62, 64, 68, 0.9);
    }

    .learn-more-market-eta-timeline-footer-table-header,
    .learn-more-market-eta-timeline-footer-table-body {
      display: grid;
      grid-template-columns: 14% 20% 26% 20% 10% 10%;
      padding: 0 10px;

      div {
        padding: 8px 10px;
      }

      &.resize {
        font-size: 13px;
        padding: 0 5px;

        div {
          padding: 8px 5px
        }
      }

      &.resize-cols {
        grid-template-columns: 14% 20% 20% 20% 13% 13%;

        div {
          padding: 8px 0px;
        }
      }
    }
  }
}