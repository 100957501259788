.footer{
    background-color: #000;
    position: fixed;
    z-index: 25;
    bottom: 0;
    color: #fff;
    height: 50px;
    .link{
        color: #fff;
        font-size: 12px;
        text-transform: uppercase;
        margin: 0px 20px;
    }
}

@media (max-width: 500px) {
    .footer {
        display: none;
    }
}