@font-face {
  font-family: 'Graphik';
  font-weight: 400;
  src: local('Graphik'), url(graphik/GraphikRegular.otf) format('opentype');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 900;
  src: local('Graphik'), url(graphik/GraphikBlack.otf) format('opentype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: local('Graphite Black'), url(opensans/OpenSans-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  src: local('Graphite Black'), url(opensans/OpenSans-Light.ttf) format('truetype');
}