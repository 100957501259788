.prism-perspective {
  .eta-showcase-content {
    gap: 150px;
  }
}

.eta-index-model {
  position: relative;
  z-index: 1;
}

.eta-index-container {
  position: relative;
  // z-index: 100;
}

.mobile-etaCube-component {
  .mobile-perspective-slider {
    .mobile-perspective-cube {
      >div {
        >div {
          >div {
            &:last-child {

              >div:nth-child(2),
              >div:nth-child(3) {
                opacity: 0 !important;
              }
            }
          }
        }
      }
    }

    .mobile-perspective-slider-content {
      height: calc(100% - 35px);

      .mobile-perspective-cube-growth,
      .mobile-perspective-cube-yield {
        flex-shrink: 0;
        margin-right: -65px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-image: url("data:image/svg+xml,%3Csvg width='84' height='81' viewBox='0 0 84 81' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.15 80.5L69.85 80.5C77.3887 80.5 83.5 74.3887 83.5 66.85L83.5 14.15C83.5 6.61131 77.3887 0.500001 69.85 0.500002L14.15 0.500006C6.61131 0.500007 0.499993 6.61132 0.499994 14.15L0.499999 66.85C0.499999 74.3887 6.61131 80.5 14.15 80.5Z' stroke='url(%23paint0_linear_547_42445)' stroke-opacity='0.36'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_547_42445' x1='4' y1='40.0825' x2='42' y2='40.0825' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .mobile-perspective-cube-yield {
        margin-right: 0;
        margin-left: -65px;

        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }

  .mobile-gd-currencyAmount,
  .mobile-dd-currencyAmount {
    display: none;
  }

  .mobile-perspective-header {
    flex: 0 0 47px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      background: linear-gradient(169.03deg, rgba(255, 255, 255, 0.4) -0.81%, rgba(255, 255, 255, 0) 136.27%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
      z-index: -1;
    }

    .mobile-perspective-search {
      font-size: 16px;
      background: linear-gradient(135deg, #E8E8E8 0%, #FFFFFF 100%);
      box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.35);
      border-radius: 15px;

      &:focus {
        outline: none;
      }
    }
  }

  .mobile-perspective-dropdown {
    height: calc(100% - 47px);

    >div {
      grid-template-columns: 25% 1fr 25%;
    }
  }
}

@function getScaling($screenWidth) {
  $widthDiff: calc($screenWidth - 360);
  $widthDiffMinus50: calc((150 - $widthDiff));
  $divide150: calc($widthDiffMinus50 / 150);
  $times28: calc($divide150 * 28);
  $divideBy100: calc($times28 / 100);
  $minus1: calc(1 - $divideBy100);
  @return $minus1;
}

// .mobile-etaCube-detailedView {

//   @media screen and (max-width: 509px) and (min-width: 435px) {
//     .mobile-etaCube-graph-wrapper {


//       position: absolute;
//       top: -30px;
//       left: 50%;
//       transform: translate(-50%, 0) scale(getScaling(435));

//     }
//   }

//   @media screen and (max-width: 434px) {
//     .mobile-etaCube-graph-wrapper {


//       position: absolute;
//       top: -69px;
//       left: 50%;
//       transform: translate(-50%, 0) scale(getScaling(360));
//     }
//   }
// }