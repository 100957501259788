@keyframes example {
  100% {
    height: 0px;
    overflow: hidden;
  }
}

.ant-tabs-content {
  transition: all 0.5s linear;
}

.show-content .ant-tabs-content {
  height: 0px !important;
  overflow: hidden !important;
}
