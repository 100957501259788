.learn-more-market {

  .learn-more-market-content {

    .learn-more-market-header {
      flex: 0 0 75px;
      display: grid;
      grid-template-columns: 1fr;
      height: 75px;

      .learn-more-market-header-title {
        height: 75px;
        padding: 22px;
        border-top-left-radius: 15px;
        overflow: hidden;
        .learn-more-market-header-title-logo {
          height: 100%;
        }
      }
    }

    .learn-more-market-body {
      flex: 0 0 calc(100% - 75px);
      height: calc(100% - 75px);
      .learn-more-market-main-tabbar-titles {
        margin-top: 7px;

        .learn-more-market-main-tabbar-title {
          padding: 6px 60px 0;
          transition: all 300ms ease;
          cursor: pointer;

          &.selected {

            &:before {
              content: '';
              position: absolute;
              top: -2px;
              right: 0;
              left: 0;
              height: 100%;
              border-bottom: none !important;
              border-radius: 20px 20px 0 0;
              transform: perspective(15px) rotateX(2deg);
            }
          }
        }

        &.red {
          .growth {

            &.selected,
            &:hover {
              color: #A94447;

              &:before {
                border: 1px solid #A94447;
              }
            }
          }

          .income {

            &.selected,
            &:hover {
              color: #FCF4ED;

              &:before {
                border: 1px solid #FCF4ED;
              }
            }
          }
        }

        &.green {
          .growth {

            &.selected,
            &:hover {
              color: #CDEAE4;

              &:before {
                border: 1px solid #CDEAE4;
              }
            }
          }

          .income {

            &.selected,
            &:hover {
              color: #205544;

              &:before {
                border: 1px solid #205544;
              }
            }
          }
        }

        &.blue {
          .growth {

            &.selected,
            &:hover {
              color: #CBEBF7;

              &:before {
                border: 1px solid #CBEBF7;
              }
            }
          }

          .income {

            &.selected,
            &:hover {
              color: #1A2741;

              &:before {
                border: 1px solid #1A2741;
              }
            }
          }
        }

        &.purple {
          .growth {

            &.selected,
            &:hover {
              color: #DFE0F1;

              &:before {
                border: 1px solid #DFE0F1;
              }
            }
          }

          .income {

            &.selected,
            &:hover {
              color: #342C53;

              &:before {
                border: 1px solid #342C53;
              }
            }
          }
        }

        .tabbar-border {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;

          &.red {
            &.growth {
              background-color: #A94447;
            }

            &.income {
              background-color: #FCF4ED;
            }
          }

          &.green {
            &.growth {
              background-color: #CDEAE4;
            }

            &.income {
              background-color: #205544;
            }
          }

          &.blue {
            &.growth {
              background-color: #CBEBF7;
            }

            &.income {
              background-color: #1A2741;
            }
          }

          &.purple {
            &.growth {
              background-color: #DFE0F1;
            }

            &.income {
              background-color: #342C53;
            }
          }

          &:last-child {
            right: 0;
          }
        }
      }

      .learn-more-market-main-tabbar-panel {
        flex: 0 0 calc(100% - 50px);
        height: calc(100% - 50px);
      }
    }
  }

  .learn-more-market-input {
    font-family: "DIN 2014", Roboto, "Segoe UI", "Helvetica Neue", sans-serif;
    font-size: 13px;
    font-weight: 600;
    padding-top: 9px;
    background: #343741;
    box-shadow: inset -1px 1px 2px rgba(34, 36, 42, 0.2), inset 1px -1px 2px rgba(34, 36, 42, 0.2), inset -1px -1px 2px rgba(70, 74, 88, 0.9), inset 1px 1px 3px rgba(34, 36, 42, 0.9);
    border-radius: 50px;
    border: none;
    line-height: 12px;
    color: #ffffff;
    max-width: 128px;


    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      font-family: inherit;
      font-size: inherit;
      font-style: italic;
      font-weight: inherit;
      color: #343741;
    }

    &:-moz-placeholder {
      font-family: inherit;
      font-size: inherit;
      font-style: italic;
      font-weight: inherit;
      color: #8B8E94;
      opacity: 1;
    }

    &::-moz-placeholder {
      font-family: inherit;
      font-size: inherit;
      font-style: italic;
      font-weight: inherit;
      color: #8B8E94;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      font-family: inherit;
      font-size: inherit;
      font-style: italic;
      font-weight: inherit;
      color: #8B8E94;
    }

    &::-ms-input-placeholder {
      font-family: inherit;
      font-size: inherit;
      font-style: italic;
      font-weight: inherit;
      color: #8B8E94;
    }

    &::placeholder {
      font-family: inherit;
      font-size: inherit;
      font-style: italic;
      font-weight: inherit;
      color: #8B8E94;
    }
  }
}

.vertical-separator { 
  height: 100%;
  min-width: 1px;
  background: linear-gradient(to bottom, transparent, #FFFFFF, transparent);
}
