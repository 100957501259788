@media (max-width: 770px) {
  .eta-cotent-container {
    .eta-match-distance-col,
    .eta-value-in-circulation-col,
    .eta-add-col {
      display: none;
    }

    .eta-remaining-term-col,
    .eta-change-col,
    .eta-growth-multiple-last-col,
    .eta-growth-multiple-offer-col {
      width: 18.75%;
    }

    .eta-type-col {
      width: 25%;
    }
  }
}

@media (max-width: 450px) {
  .eta-type-col {
    .asx-row {
      padding: 0 5px 0 3px;
      font-size: 8px;
    }
  }
  .eta-add-col {
    width: 35px;
  }

  .eta-cotent-container {
    .eta-remaining-term-col,
    .eta-change-col,
    .eta-growth-multiple-last-col,
    .eta-growth-multiple-offer-col {
      font-size: 12px;
    }

    .eta-remaining-term-col {
      padding-left: 5px;
    }
  }
}
