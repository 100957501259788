.infographics {
  .infographicsContent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 185px;
    height: 40px;
    transition: all 300ms ease;
    color: transparent;
    cursor: pointer;

    &.active {
      color: #ffffff;
    }

    &.hover {
      color: #474C55;

      &.focus {
        color: #ffffff;
      }
    }

    &.hover {
      &.focus {
        >div {
          .infographicsContentGlow {
            opacity: 1;
          }
        }
      }
    }

    &.adjusting {
      color: #ffffff;

      >div {
        .infographicsContentGlow {
          opacity: 1;
        }
      }
    }

    &.static {
      color: #474C55 !important;

      &:hover {
        color: #ffffff !important;
      }
    }

    >div {
      position: relative;
      width: 100%;
      user-select: none;

      >div:first-child {
        z-index: 2;
      }

      .infographicsContentGlow {
        position: absolute;
        width: 183px;
        height: 183px;
        opacity: 0;
        transition: all 300ms ease;
        z-index: -1;
        filter: blur(150px);
        pointer-events: none;
      }
    }

    .infographicComponentWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.topRight {
      top: 65px;
      right: 65px;
      transform: rotate(30deg) skewX(-30deg) rotateX(45deg);
    }

    &.centerRight {
      top: calc(50% - 37px);
      right: -25px;
      transform: rotate(90deg);
    }

    &.bottomRight {
      right: 60px;
      bottom: 95px;
      transform: rotate(150deg) skewX(30deg) rotateX(45deg);

      >div {
        transform: rotate(180deg);
      }
    }

    &.bottomLeft {
      bottom: 95px;
      left: 60px;
      transform: rotate(210deg) skewX(-30deg) rotateX(-45deg);

      >div {
        transform: rotate(180deg);
      }
    }

    &.centerLeft {
      top: calc(50% - 37px);
      left: -25px;
      transform: rotate(270deg);
    }

    &.topLeft {
      top: 65px;
      left: 65px;
      transform: rotate(330deg) skewX(30deg) rotateX(-45deg);
    }

    &.outerBottomRight {
      right: 40px;
      bottom: 45px;
      transform: rotate(150deg) skewX(30deg) rotateX(45deg);

      >div {
        transform: rotate(180deg);
      }
    }

    &.outerTopLeft {
      top: 20px;
      left: 40px;
      transform: rotate(150deg) skewX(30deg) rotateX(45deg);

      >div {
        transform: rotate(180deg);
      }
    }
  }
}