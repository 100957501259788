.gray-btn {
  background: linear-gradient(135deg, #e8e8e8 0%, #ffffff 100%);
  box-shadow: -0.897126px 0.897126px 1.79425px rgba(207, 207, 207, 0.2),
    0.897126px -0.897126px 1.79425px rgba(207, 207, 207, 0.2),
    -0.897126px -0.897126px 1.79425px rgba(255, 255, 255, 0.9),
    0.897126px 0.897126px 2.69138px rgba(207, 207, 207, 0.9);
  backdrop-filter: blur(23.1159px);
}

.securities-rows {
  background: transparent;
}

.security-border-bottom {
  height: 0.5px;
  background-image: linear-gradient(90deg, transparent, #fefefe, transparent);
  top: initial;
}

.active-security {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.55), inset 0px 4px 10px #000000;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(135deg, #1F1F23 0%, #232327 100%);
}

.bg-eta-content {
  background: linear-gradient(0deg, #000000, #000000), linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(135deg, #1F1F23 0%, #232327 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.55), inset 0px 4px 10px #000000;
}

.bg-eta-item {
  background: linear-gradient(135deg, #343741 0%, #1F1F22 100%);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25), 0px -1px 4px rgba(0, 0, 0, 0.35);
  border-radius: 25px;
}
