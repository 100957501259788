@import './cubes';
@import './investment-tabs';
@import './intro-sections';
@import './prism-scrollbar';
@import './slide-control';
@import './eta-showcase.scss';
@import './eta-cube.scss';
@import './eta-borders.scss';
@import './home-pane.scss';
@import './markets.scss';
@import './markets-region.scss';
@import './markets-table.scss';
@import './markets-eta.scss';
@import './input.scss';
@import './toolbar.scss';
@import './eta-details.scss';
// @import '~animate.css';

iframe {
  display: none !important;
}

// .sign-in-btn {
//   // background: linear-gradient(135deg, #343741 0%, #202022 100%);
//   box-shadow: -1px 1px 2px rgba(22, 22, 24, 0.2),
//     1px -1px 2px rgba(22, 22, 24, 0.2), -1px -1px 2px rgba(46, 46, 48, 0.9),
//     1px 1px 3px rgba(22, 22, 24, 0.9);
//   border-radius: 20px;
//   border: 0;
// }

#root {
  width: 100%;
  height: 100%;
}

.markets-toggler {
  display: none;
}

@media screen and (max-width: 950px) {
  .main {
    flex-grow: 1;
    padding: 0;
    .w-1\/3,
    .w-2\/3 {
      width: 100%;
      padding: 0;
    }
  }
}


@media screen and (max-width: 768px) {
  .main {
    .home-pane {
      .slide-control {
        display: none;
      }

      .top-bar {
        background: linear-gradient(169.03deg, rgba(255, 255, 255, 0.4) -0.81%, rgba(255, 255, 255, 0) 136.27%);

        button:not(.active) {
          display: none;
        }

        button {
          span {
            color: #000000;
          }
        }
      }

      .markets-toggler {
        display: flex;

        svg {
          // background: conic-gradient(from 0deg at 50% 50.13%, #8B8E94 0deg, #5F6369 360deg);
        }
      }
    }

    // .pane-articles {
    //   display: none;
    // }

    // +div {
    //   display: none;
    // }
  }
}