.alternate-position {
  .section-label {
    font-size: 21px;
    color: #FFF;
  }

  .section-description {
    font-size: 12px;
    color: #FFF;
  }

  .step-info {
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid #2C2F33;
    backdrop-filter: blur(42px);
    border-radius: 3px;

    .step-label {
      background: linear-gradient(0deg, #2C2F33, #2C2F33), linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      border: 1px solid #2C2F33;
      backdrop-filter: blur(42px);
      color: #FFF;
      font-weight: bold;
      border-radius: 1px;
      padding: 2px 10px;
      flex-shrink: 0;
    }

    .step-description {
      min-height: 29px;
      color: #FFF;
    }
  }

  .section-logo img {
    width: 100px;
    height: 100px;
  }

  .security-logo img {
    height: 35px;
  }

  .eta-info {
    // background: rgba(44, 47, 51, 0.1);
    // border-radius: 3px;
  }

  .eta-dropdown select {
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  }

  .eta-details {
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(42px);
    border-radius: 3px;

    .eta-header {
      font-weight: 500;
      font-size: 12px;
      color: #2C2F33;
    }

    .eta-label {
      font-weight: 600;
      font-size: 15px;
      color: #000000;
    }

    .eta-logo {
      color: #fff;
    }

    .statistic-content {
      .ant-statistic-content {
        font-size: 14px;
        color: #2C2F33;
      }
    }
  }

  .footer-info .ant-btn-primary {
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #2C2F33;
    backdrop-filter: blur(26.0522px);
    border-radius: 3.44028px;
    border-color: #2C2F33;

    .next-icon {
      display: inline;
      padding-left: 10px;
    }
  }
}