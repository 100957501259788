.page-content {
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: auto;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}
