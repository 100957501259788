.maturity-term-slider {
  position: relative;
  width: 100%;
  height: 5px;
  user-select: none;

  .maturity-term-slider-input {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    appearance: none;
    user-select: none;
    z-index: 3;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-thumb {
      width: 23px;
      height: 23px;
      border: none;
      box-shadow: none;
      background: none;
      appearance: none;
    }

    &::-moz-range-thumb {
      width: 23px;
      height: 23px;
      border: none;
      box-shadow: none;
      background: none;
      appearance: none;
    }
  }
}

.maturity-term-slider-track {
  border-radius: 15px;
  background-color: #C0C1C6;
  z-index: 2;

  .maturity-term-slider-track-thumb {
    position: absolute;
    flex-shrink: 0;
    top: 50%;
    right: -11.5px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background: #C0C1C6;
    border: 2.01904px solid #797D82;
    box-shadow: inset -2px 2px 4px rgba(77, 77, 79, 0.2), inset 2px -2px 4px rgba(77, 77, 79, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 2px 2px 5px rgba(77, 77, 79, 0.9);
    transform: translateY(-50%);
  }
}

.maturity-term-slider-range {
  width: 100%;
  background: #F5F5F5;
  opacity: 0.7;
  box-shadow: inset -3px 3px 6px rgba(172, 172, 172, 0.2), inset 3px -3px 6px rgba(172, 172, 172, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.9), inset 3px 3px 8px rgba(172, 172, 172, 0.9);
  border-radius: 15px;
  z-index: 1;
}