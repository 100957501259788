.login-container {
  width: 100%;
  .login-body {
    display: flex;
    width: 100%;
    flex-direction: row;
    .center-layout {
      justify-content: center;
      width: 450px;
      height: 500px;
      margin-top: 95px;
      margin-left: auto;
      align-items: center;
      background: #f7f7f7;
      .Sign-in-header {
        font-family: Graphik;
        font-size: 28px;
        margin-left: 30px;
        margin-top: 20px;
      }

      .username {
        width: 80%;
        margin-left: 30px;
        margin-top: 20px;
      }
      .passowrd {
        width: 80%;
        margin-left: 30px;
        margin-top: 30px;
      }

      .sign-in-button {
        width: 100px;
        height: 40px;
        margin-top: 30px;
        margin-left: 30px;
        border-radius: 5px;
        font-size: 18px;
        color: grey;
        border-color: #737477;
      }

      .other-signin-header {
        font-family: Graphik;
        font-size: 19px;
        color: grey;
        margin-left: 35px;
        margin-top: 15px;
      }

      .register-header {
        font-family: Graphik;
        font-size: 17px;
        color: grey;
        margin-left: 35px;
        margin-top: 40px;
        .register-button {
          font-size: 17px;
        }
      }

      .other-signin {
        display: flex;
        flex-direction: row;

        .facebook-icon {
          font-size: 250%;
          margin-left: 35px;
          color: gray;
        }
        .google-icon {
          font-size: 250%;
          margin-left: 30px;
          color: gray;
        }

        .linked-in-icon {
          font-size: 250%;
          margin-left: 30px;
          color: gray;
        }
      }
    }

  }
  .login-footer {
    .content {
      display: inline-block;
      width: 40%;
      font-family: Graphik;
      font-style: normal;
      font-size: 14px;
      line-height: 19px;
      margin-left: 50px;
      color: #7a7a7a;
      margin-top: 10px;
    }
    .general-enquires {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
